<template>
    <div class="MarketingSucceed">
        <div class="success-content">
            <i class="iconfont">&#xe730;</i>
            <p class="text">创建成功！</p>
        </div>
        <div class="btn">
            <el-button @click="toList" class="btn-blue-line-all">返回</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "StoreMarketingSucceed",
        methods: {
            toList(){
                this.$router.push({
                    path: '/admin/operatedata/storemarket'
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .MarketingSucceed{
        .success-content {
            margin-top: 155px;
            text-align: center;
            .iconfont {
                color: #1FD18C;
                font-size: 52px;
            }
            .text {
                font-size: 24px;
                margin: 20px 0 0;
                font-weight: bold;
            }
        }
        .btn{
            margin-top: 60px;
            text-align: center;
        }
    }
</style>